<template>
  <div class="page3">
    <div class="item">
      <div class="item_name">
        <div class="item_name_zh">姓名</div>
        <div class="item_name_en">Name</div>
      </div>
      <div class="item_control_wrapper">
        <input class="item_control" v-model="form.name" type="text" />
      </div>
    </div>
    <div class="item">
      <div class="item_name">
        <div class="item_name_zh">电话</div>
        <div class="item_name_en">Telephone Number</div>
      </div>
      <div class="item_control_wrapper">
        <input class="item_control" v-model="form.phone" type="number" />
      </div>
    </div>
    <div class="item">
      <div class="item_name">
        <div class="item_name_zh">身份证号</div>
        <div class="item_name_en">ID Card/Passport</div>
      </div>
      <div class="item_control_wrapper">
        <input class="item_control" v-model="form.identity_number" type="text" />
      </div>
    </div>

    <div class="item">
      <div class="item_name">
        <div class="item_name_zh">品牌</div>
        <div class="item_name_en">Brand</div>
      </div>
      <div class="item_control_wrapper" @click="showBrand = true">
        <input class="item_control" v-model="form.brand" type="text" readonly />
      </div>
    </div>

    <div class="item">
      <div class="item_name">
        <div class="item_name_zh">经销商</div>
        <div class="item_name_en">Dealer Name</div>
      </div>
      <div class="item_control_wrapper">
        <input class="item_control" v-model="form.dealer" type="text" />
      </div>
    </div>

    <div class="item">
      <div class="item_name">
        <div class="item_name_zh">岗位</div>
        <div class="item_name_en">Position</div>
      </div>
      <div class="item_control_wrapper" @click="showPosition = true">
        <input class="item_control" v-model="form.position" type="text" readonly />
      </div>
    </div>

    <div class="item">
      <div class="item_name">
        <div class="item_name_zh">到达日期</div>
        <div class="item_name_en">Arriving Date</div>
      </div>
      <div class="item_control_wrapper" @click="clickDate('arrived_date')">
        <input class="item_control" v-model="form.arrived_date" readonly type="text" />
      </div>
    </div>
    <div class="item">
      <div class="item_name">
        <div class="item_name_zh">离开日期</div>
        <div class="item_name_en">Date leave Dali</div>
      </div>
      <div class="item_control_wrapper" @click="clickDate('leave_date')">
        <input class="item_control" v-model="form.leave_date" readonly type="text" />
      </div>
    </div>

    <div class="item">
      <div class="item_name">
        <div class="item_name_zh">性别</div>
        <div class="item_name_en">Gender</div>
      </div>
      <div class="item_control_wrapper" @click="clickGender">
        <input class="item_control" v-model="form.gender" type="text" readonly />
      </div>
    </div>

    <div class="item" v-if="form.gender">
      <div class="item_name">
        <div class="item_name_zh">服装尺码</div>
        <div class="item_name_en">Cloth Size</div>
      </div>
      <div class="item_control_wrapper" @click="clickSize">
        <input class="item_control" v-model="form.size" type="text" readonly />
      </div>
    </div>
    <div class="item">
      <div class="item_name">
        <div class="item_name_zh">航班号</div>
        <div class="item_name_en">Flight to Da</div>
      </div>
      <div class="item_control_wrapper">
        <input class="item_control" v-model="form.flight_number" type="text" />
      </div>
    </div>
    <div class="page3_submit" @click="onSubmit">立即提交</div>
    <div class="private_zh">对于您的个人信息我们仅用于本此活动试驾保险购买等内部情况使用信息收集截至日期: 2024.11.15</div>
    <div class="private_en">For your personal information, we only use it for internalpurposes such as test drive insurance purchaseInformation collection expiration date: 2024.11.15</div>
    <van-calendar color="#000" :show-confirm="false" :show-title="false" v-model="showDate" @confirm="dateConfirm" />
    <van-action-sheet v-model="showSize" :actions="sizeActions" @select="selectSize" />
    <van-action-sheet v-model="showGender" :actions="genderActions" @select="selectGender" />
    <van-action-sheet v-model="showPosition" :actions="positionActions" @select="selectPosition" />
    <van-action-sheet v-model="showArrived" :actions="arrivedActions" @select="selectArrived" />
    <van-action-sheet v-model="showLeave" :actions="leaveActions" @select="selectLeave" />
    <van-action-sheet v-model="showBrand" :actions="brandActions" @select="selectBrand" />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { Toast } from 'vant';
export default {
  name: 'Page3',
  data() {
    return {
      // 提交表单
      form: {},
      // 日期选择
      showDate: false,
      // 服装尺码选择
      showSize: false,
      // 性别选择
      showGender: false,
      // 岗位选择
      showPosition: false,
      // 到达日期选择
      showArrived: false,
      // 离开日期选择
      showLeave: false,
      // 品牌选择
      showBrand: false,
      // 服装尺码选项
      sizeActions: [],
      // 性别选项
      genderActions: Object.freeze([{ name: '男' }, { name: '女' }]),
      // 岗位选项
      positionActions: [{ name: '总经理(General Manager)' }, { name: '销售精英(Dealer Sales)' }, { name: '售后技师(Dealer aftersales)' }],
      // 到达日期选项
      arrivedActions: [],
      // 离开日期选项
      leaveActions: [],
      // 品牌选项
      brandActions: [{ name: 'FAW-VW 一汽大众' }, { name: 'DSSO 大众安徽' }, { name: 'VGIC 大众进口车' }],
    };
  },
  mounted() {
    this.form = this.$u.GetStorage('result') || {};
  },
  methods: {
    // 提交
    onSubmit() {
      if (this.done) return;
      this.done = true;
      const valid = this.validateForm() || true;
      if (!valid) return (this.done = null);
      const loading = Toast.loading({
        message: '提交中...',
        forbidClick: true,
      });
      this.$api.post('/addForm', this.form).then(({ error }) => {
        loading.clear();
        this.done = null;
        if (error) {
          console.log(error);
        } else {
          this.$u.SetStorage('result', this.form);
          Toast.success('报名成功');
          setTimeout(() => {
            this.$router.go(-1);
          }, 1000);
        }
      });
    },
    // 表单校验
    validateForm() {
      const { name, phone, identity_number, brand, dealer, arrived_date, leave_date, size, gender, flight_number } = this.form;
      const v1 = !(name && phone && identity_number && brand && dealer && arrived_date && leave_date && size && gender && flight_number);
      if (v1) return this.$toast('请将信息填写完整'), false;
      const v2 = this.$u.checkPhone(phone);
      if (!v2) return this.$toast('请填写正确手机号'), false;
      const v3 = this.$u.checkId(identity_number);
      if (!v3) return this.$toast('请填写正确身份证号'), false;
      return true;
    },
    // 服装尺码选择
    clickSize() {
      this.showSize = true;
    },
    // 尺码选择完成
    selectSize(item) {
      this.showSize = false;
      this.form.size = item.name;
    },
    // 性别选择完成
    selectGender(item) {
      this.showGender = false;
      this.sizeActions =
        item.name === '男'
          ? [
              { name: 'L(158-163cm/90-110斤)' },
              { name: 'XL(163-168cm/110-130斤)' },
              { name: '2XL(168-175cm/130-150斤)' },
              { name: '3XL(175-180cm/150-170斤)' },
              { name: '4XL(180-185cm/170-190斤)' },
              { name: '5XL(185-190cm/190-210斤)' },
            ]
          : [
              { name: 'M(153-158cm/75-95斤)' },
              { name: 'L(158-163cm/95-115斤)' },
              { name: 'XL(163-168cm/115-130斤)' },
              { name: '2XL(168-175cm/130-145斤)' },
              { name: '3XL(175-180cm/145-160斤)' },
              { name: '4XL(180-185cm/160-175斤)' },
            ];

      this.form.gender = item.name;
    },
    selectPosition(item) {
      this.showPosition = false;
      if (item.name === '总经理(General Manager)') {
        this.arrivedActions = [{ name: '11月13日 06:00-12:00' }];
        this.form.arrived_date = '11月13日 06:00-12:00';
      } else {
        this.arrivedActions = [{ name: '11月12日 06:00-12:00' }, { name: '11月12日 12:00-18:00' }, { name: '11月12日 18:00-24:00' }];
        if (this.form.arrived_date === '11月13日 06:00-12:00') {
          this.form.arrived_date = '11月12日 06:00-12:00';
        }
      }
      this.leaveActions = [{ name: '11月14日 06:00-12:00' }];
      this.form.leave_date = '11月14日 06:00-12:00';
      this.form.position = item.name;
    },
    // 到达日期选择完成
    selectArrived(item) {
      this.showArrived = false;
      this.form.arrived_date = item.name;
    },
    // 离开日期选择完成
    selectLeave(item) {
      this.showLeave = false;
      this.form.leave_date = item.name;
    },
    // 品牌选择完成
    selectBrand(item) {
      this.showBrand = false;
      this.form.brand = item.name;
    },
    // 性别选择
    clickGender() {
      this.showGender = true;
    },
    // 日期选择
    clickDate(field) {
      if (field === 'arrived_date') {
        this.showArrived = true;
      } else {
        this.showLeave = true;
      }
    },
    // 日期选择完成
    dateConfirm(date) {
      this.showDate = false;
      this.form[this.field] = dayjs(date).format('YYYY-MM-DD');
    },
  },
};
</script>

<style scoped>
.page3 {
  min-height: 100vh;
  background: #000;
  box-sizing: border-box;
  padding: 50px 35px 30px;
}
.item {
  height: 48px;
  background: #fff;
  border-radius: 24px;
  display: flex;
  align-items: center;
  padding: 0 20px 0 32px;
  margin-bottom: 16px;
}
.item_name {
  flex: 0 0 auto;
  margin-right: 20px;
}
.item_name_zh {
  font-family: HYQiHei, HYQiHei;
  font-size: 12px;
  color: #032152;
}
.item_name_en {
  font-size: 8px;
  color: #032152;
}
.item_control_wrapper {
  flex: 1;
}
.item_control {
  width: 100%;
  text-align: right;
}
.item_control:disabled {
  background: #fff;
}
.page3_submit {
  width: 140px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 3px;
  margin: 24px auto 40px;
}
.private_zh,
.private_en {
  font-family: HYQiHei, HYQiHei;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
}
.private_en {
  font-size: 10px;
  margin-top: 16px;
  padding: 0 20px;
}
</style>
